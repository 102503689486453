import React from "react"
import Layout from "../components/layout"
import { Gallery } from "gatsby-theme-gallery"
import InstagramVideo from "../components/instagram-video"

export default ({ data }) => {
  return (
    <Layout title="Galeria">
      <div className="row">
        <div className="events-wrapper">
          <h1 className="center background-title">Galeria</h1>

          <div className="gallery-content">
            <div className="instagram-videos">
              <div className="videos">
                <div>
                  <InstagramVideo />
                </div>
              </div>
            </div>

            <div className="gallery-images">
              <Gallery />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesQuery {
    # the filter is usefull if you have multiple source-filesystem instances
    # the name "images" is set in the gatsby-config
    allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            resolutions(width: 540, height: 540) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
  }
`
